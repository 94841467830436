<template>
  <div style="height: 100%;">
    <div style="width: 100%; display: flex; align-items: center; align-content: center; text-align: center;
      background: #2d8cf0; color: white; height: 50px; line-height: 50px;">
      <span style="margin: 0 auto 0 auto;">我有话说</span>
    </div>

    <div style="height: calc(100% - 50px);overflow-y: auto">
      <div style="height: auto; margin-top: 30px; margin-right: 0px; padding: 20px">
        <el-form :model="form" label-width="80px" label-position="left">
          <el-form-item label="姓名">
            <el-input v-model="form.name" maxlength="20" show-word-limit/>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.phone" maxlength="50" show-word-limit/>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="form.sex">
              <el-radio label="保密" />
              <el-radio label="男" />
              <el-radio label="女" />
            </el-radio-group>
          </el-form-item>
          <el-form-item label="* 建议">
            <el-input v-model="form.desc" type="textarea"  maxlength="300" show-word-limit minlength="10" :autosize="{minRows: 5, maxRows: 20}"/>
          </el-form-item>
        </el-form>

        <div class="el-form-item__label" style="margin-bottom: 0px; width: 80px; text-align: left;">图片</div>
        <div style="width: 100%; padding: 0 0">
          <el-upload class="avatar-uploader"
                     ref="otherLicense"
                     action
                     :auto-upload="false"
                     :on-preview="handlePicPreview"
                     :before-remove="beforeRemove"
                     :on-remove="remove"
                     :file-list="fileList"
                     :on-change="otherSectionFile"
                     list-type="picture-card"
                     :limit="9"
                     multiple>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>



        <div style="width: 100%; display: flex; align-items: center; align-content: center">
          <el-button type="primary" @click="onSubmit" style="width: 70%; margin: 50px auto 0 auto;">提交</el-button>
        </div>

      </div>
      <el-dialog v-model="dialogVisible">
        <img style="width: 100%" :src="dialogImageUrl" class="avatar">
      </el-dialog>
    </div>


  </div>
</template>

<script>
export default {
  name: "Advice",
  data () {
    return {
      form:{
        name: '',
        phone: '',
        sex: '保密',
        desc: '',
      },
      fileList: [],
      otherLicense:[],
      dialogVisible: false,
      dialogImageUrl: '',
      imageUrlList: [],
    }
  },
  methods:{
    onSubmit(){

      let name = this.form.name;
      let phone = this.form.phone;
      let sex = this.form.sex;
      let desc = this.form.desc;

      if (!desc || desc.length < 10){
        this.$message.error("建议内容需要10个字以上! ");
        return;
      }

      this.imageUrlList = [];
      this.fileList.map((item,index)=>{
        this.imageUrlList.push(item.imageID)
      })

      this.apiUtils.advice({name: name, phone: phone, sex: sex, desc: desc, imageUrlList: this.imageUrlList.join(",")}).then(res => {
        if (res.code == 200){
          this.$message.success("提交成功! ");
          this.form.desc = ""
          this.fileList = []
          this.imageUrlList = []
        } else {
          this.$message.error("提交失败! " + res.message);
          console.log(res);
        }

      });
    },
    handlePicPreview(file){

      this.dialogImageUrl = this.apiUtils.path + "/ly/image/get/" + file.imageID;
      this.dialogVisible = true;

      console.error("大图：" + this.dialogImageUrl)
    },
    handleRemove(file, fileList) {
      this.fileList.map((item,index)=>{
        if(item.uid==file.uid){
          this.fileList.splice(index,1)
        }
      })
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确认删除${file.name}吗`, "确认删除");
    },
    remove(file, fileList){
      this.fileList.map((item,index)=>{
        if(item.uid==file.uid){
          this.fileList.splice(index,1)
        }
      })

      this.imageUrlList = [];
      this.fileList.map((item,index)=>{
        this.imageUrlList.push(item.imageID)
      })
    },
    otherSectionFile(file){
      console.error("otherSectionFile")
      const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error('只能是图片!');
        this.$refs.upload.clearFiles();
        this.otherFiles = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        this.$refs.upload.clearFiles();
        this.otherFiles = null;
        return;
      }
      this.otherFiles = file.raw;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append('file', this.otherFiles);
      formData.append('bindType', 'longyou_hz');
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        methods: 'post'
      }
      this.axios.post(this.apiUtils.path + "/ly/image/upload",formData,config).then(res=>{
        console.error(res.data)
        if(res.data.code == '200'){
          file.imageID = res.data.data.imageID
          this.imageUrlList.push(res.data.data.imageID)
          this.fileList.push(file)
          this.otherPhotoObj = res.data.data.imageID
          this.otherPhoto = res.data.data.imageID
          this.otherLicense.push({
            certificationName: this.form.certificationName,
            certificationObj: this.otherPhotoObj,
            certificationUrl: this.apiUtils.path + "/ly/image/get/" +this.otherPhoto
          })

        }else{
          this.$message.error(res.data.message)
        }
      })
    },
  },
}
</script>
<style scoped>

</style>
